import ApiService from '@/modules/api/services/ApiService'
import { downloadFile } from '@/modules/global/utilities/downloadFile'
import * as types from '@/types'

const state = {
  items: [],
  userTransactionProducts: [],
  downloadEntryConfirmationStatus: null,
  cancelProductStatus: null,
  selectedEntry: null,
  productForSelectedEntry: null,
  selectedParticipantForProduct: null,
  userEntriesByEvent: null
}

const getters = {
  getUserEntriesByEvent: state => state.userEntriesByEvent,
  getSelectedParticipant: state => state.selectedParticipantForProduct,
  getSelectedEntry: state => state.selectedEntry,
  getProductSelectedEntry: state => state.productForSelectedEntry
}

const actions = {
  async [types.GET_PRODUCTS]({ commit }, payload) {
    const { data } = await ApiService.get(`/products?productType=${payload.productType}`)
    commit(types.SET_PRODUCTS, data.data)
  },
  async [types.FILTER_PRODUCTS]({ commit }, payload) {
    const { data } = await ApiService.get(`/products?productType=${payload.productType}&filter=${payload.filter}`)
    commit(types.SET_PRODUCTS, data.data)
  },
  async [types.GET_USER_TRANSACTION_PRODUCTS]({ commit }, payload) {
    try {
      const { userId } = payload
      const url = `users/${userId}/products`
      const { data } = await ApiService.get(url, '')
      commit(types.GET_USER_TRANSACTION_PRODUCTS_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.CLEAR_USER_TRANSACTION_PRODUCTS)
      return Promise.reject(error)
    }
  },
  async [types.DOWNLOAD_PRODUCT_CONFIRMATION_REQUEST]({ commit }, payload) {
    try {
      const { userId, transactionId } = payload
      const url = `users/${userId}/products/${transactionId}/confirmation`
      const params = {}
      const responseType = 'blob'
      const { data } = await ApiService.request('get', url, params, { responseType })

      downloadFile(data, 'product-confirmation.pdf', 'application/pdf')

      commit(types.DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.DOWNLOAD_PRODUCT_CONFIRMATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.DOWNLOAD_BALANCE_CONFIRMATION_REQUEST]({ commit }, payload) {
    try {
      const { userId, cartItemId } = payload
      const url = `users/${userId}/gift/${cartItemId}/confirmation`
      const params = {}
      const responseType = 'blob'
      const { data } = await ApiService.request('get', url, params, { responseType })

      downloadFile(data, 'product-confirmation.pdf', 'application/pdf')

      commit(types.DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.DOWNLOAD_BALANCE_CONFIRMATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.CANCEL_PRODUCT_REQUEST]({ commit, dispatch }, payload) {
    try {
      const { userId, cartId } = payload
      const url = `users/${userId}/carts/${cartId}/cancel`
      const params = {}
      await ApiService.request('post', url, params)
      dispatch('get_user_transaction_products', { userId: userId })
      commit(types.CANCEL_PRODUCT_REQUEST_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.CANCEL_PRODUCT_REQUEST_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_FELLOWS_REQUEST]({ commit }, payload) {
    try {
      const { userId } = payload
      const url = `users/${userId}`
      const slug = 'fellows'
      const { data } = await ApiService.get(url, slug)
      commit(types.GET_USER_FELLOW_RUNNERS_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_FELLOW_RUNNERS_ERROR)
      return Promise.reject()
    }
  },
  async [types.GET_USER_ENTRIES_FOR_EVENT_REQUEST]({ state, commit }, productId) {
    try {
      const url = `entries/products/${productId}/users/${state.selectedParticipantForProduct.uid}`
      const { data } = await ApiService.get(url)
      commit(types.GET_USER_ENTRIES_FOR_EVENT_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_FELLOW_RUNNERS_ERROR)
      return Promise.reject()
    }
  },
  async [types.GET_USER_ENTRIES_FOR_EVENT_IN_CART_REQUEST]({ state, commit }, { productId, entries, userId }) {
    try {
      const url = `users/${userId}/carts/entry/product`
      const params = {
        entries: entries,
        productId: productId,
        participantId: state.selectedParticipantForProduct.uid
      }
      const { data } = await ApiService.save(url, params)
      commit(types.GET_USER_ENTRIES_FOR_EVENT_SUCCESS, data)
      return Promise.resolve()
    } catch (error) {
      commit(types.GET_USER_FELLOW_RUNNERS_ERROR)
      return Promise.reject()
    }
  }
}

const mutations = {
  [types.SET_PRODUCTS](state, items) {
    state.items = items
  },
  [types.GET_USER_TRANSACTION_PRODUCTS_SUCCESS](state, { data }) {
    state.userTransactionProducts = data
  },
  [types.CLEAR_USER_TRANSACTION_PRODUCTS](state) {
    state.userTransactionProducts = []
  },
  [types.DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS](state) {
    state.downloadProductConfirmationStatus = 'success'
  },
  [types.DOWNLOAD_PRODUCT_CONFIRMATION_ERROR](state) {
    state.downloadProductConfirmationStatus = 'error'
  },
  [types.DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS](state) {
    state.downloadBalanceConfirmationStatus = 'success'
  },
  [types.DOWNLOAD_BALANCE_CONFIRMATION_ERROR](state) {
    state.downloadBalanceConfirmationStatus = 'error'
  },
  [types.CANCEL_PRODUCT_REQUEST_SUCCESS](state) {
    state.cancelProductStatus = 'success'
  },
  [types.CANCEL_PRODUCT_REQUEST_ERROR](state) {
    state.cancelProductStatus = 'error'
  },
  [types.SET_PRODUCT_ENTRY](state, entry) {
    state.selectedEntry = entry
  },
  [types.SET_PRODUCT_FOR_ENTRY](state, product) {
    state.productForSelectedEntry = product
  },
  [types.SET_PRODUCT_PARTICIPANT](state, user) {
    state.selectedParticipantForProduct = user
  },
  [types.GET_USER_ENTRIES_FOR_EVENT_SUCCESS](state, entries) {
    state.userEntriesByEvent = entries.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
